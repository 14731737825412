var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card gutter-b card-stretch"},[_c('div',{staticClass:"table-responsive"},[(_vm.loading)?_c('div',{staticClass:"p-10"},[_c('div',{staticClass:"spinner spinner-primary spinner-md spinner-center"}),_c('p',{staticClass:"text-6xl font-bold text-center text-gray-500 animate-pulse mt-5"},[_vm._v(" Loading... ")]),(_vm.error.length > 0)?_c('v-alert',{attrs:{"border":"top","color":"red lighten-2","dark":""}},[_vm._v(" "+_vm._s(_vm.error.response)+" ")]):_vm._e()],1):_vm._e(),(!_vm.loading)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.data,"styleClass":"vgt-table /*striped*/","fixed-header":false,"compactMode":"","pagination-options":{
        enabled: false,
        perPage: 50,
        mode: 'pages'
      },"search-options":{
        enabled: false,
        placeholder: 'Search for a customer',
      }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'diffDate')?_c('span',[(props.row.diffDate == 0 && props.row.diffDate != '')?_c('span',{staticClass:"svg-icon"},[_c('i',{staticClass:"flaticon2-check-mark text-success"})]):_vm._e(),(props.row.diffDate == '')?_c('span',{staticClass:"svg-icon"},[_c('i',{staticClass:"flaticon-cancel text-warning"})]):_vm._e(),(props.row.diffDate != 0)?_c('span',{staticClass:"text-muted font-weight-bold"},[_vm._v(_vm._s(props.row.diffDate))]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3956432638)},[_vm._v(" > ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }